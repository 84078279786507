import React from 'react'
import { PageProps } from 'gatsby'
import { Layout } from '../../components/layout'
import { SEO } from '../../components/seo'
import { Menu } from '../../components/menu'

import * as styles from '../../styles/pages/solutions.module.scss'
import mainHeader from '../../images/solutions/complianceMain.png'

import { Contact } from '../../components/contact/contact'
import { Footer } from '../../components/footer'
import { FaqSection } from '../../components/faq'
import clsx from 'clsx'
import { MethodologySection } from '../../components/methodology'
import { SliderCustomers } from '../../components/sliderCustomers/sliderCustomers'
import { StatisticsSection } from '../../components/statistics'
import { sgiList } from '../../utils/customersList'

const faqItems = [
  {
    question: 'Como funciona o compliance na prática?',
    answer:
      'No âmbito empresarial, compliance é o conjunto de disciplinas a fim de se fazer cumprir normas legais e regulamentares, políticas e diretrizes institucionais, bem como evitar, detectar e tratar desvios ou inconformidades. Na prática, envolve diretrizes relacionadas à ética no trabalho, transparência e conformidade com leis anticorrupção e antissuborno.',
  },
  {
    question: 'Quais os benefícios de implementar o compliance empresarial?',
    answer:
      'Adotar boas práticas de compliance proporciona processos mais eficazes, aumento da produtividade, maior competitividade de mercado, redução de custos, prevenção de riscos, maior transparência e satisfação dos colaboradores.',
  },
  {
    question: 'Como funciona um projeto de compliance?',
    answer:
      'O termo compliance significa estar em conformidade. Esta conformidade está relacionada a normas legais e regulamentares, políticas e diretrizes institucionais, que podem variar de acordo com o segmento de atuação da empresa. Trabalhamos no planejamento do que deve ser atendido, implementação das boas práticas, auditoria e controle e melhoria contínua.',
  },
  {
    question: 'Quanto custa a consultoria para implantação de compliance?',
    answer:
      'O custo da consultoria é variável. Para realizar a estimativa de custo é necessário considerar o escopo de implantação - que pode abranger a segurança da informação, práticas anticorrupção e antissuborno, gestão de riscos, saúde e segurança ocupacional, meio ambiente e qualidade de produtos e serviços - o tamanho da empresa e a modalidade de atendimento, se presencial, remota ou híbrida.',
  },
]

const featuresItems = [
  {
    title: `Gestão de Compliance`,
    description: (
      <>
        Implementação de ponta-a-ponta, desde a criação do Comitê de Compliance,
        até o estabelecimento de todos os processos, políticas e procedimentos.
        Treinamos toda a equipe para sustentabilidade da nova área, com base nas
        melhores práticas da ISO 37301.
      </>
    ),
  },
  {
    title: `Gestão de Riscos`,
    description: (
      <>
        Fazemos o levantamento de todos os riscos de processos e estratégicos,
        definimos os proprietários de cada risco e na sequência elaboramos a
        Matriz de Riscos com suas classificações, controles e indicadores, com
        base nas melhores práticas da ISO 31000.
      </>
    ),
  },
  {
    title: `Anticorrupção e antissuborno`,
    description: (
      <>
        Iremos implementar as boas práticas da norma ISO 37001, com o objetivo
        de combater atos ilícitos por meio da divulgação de uma cultura de
        integridade, transparência e conformidade junto às leis e
        regulamentações aplicáveis.
      </>
    ),
  },
  {
    title: `ESG`,
    description: (
      <>
        Fazemos um diagnóstico e na sequência a definição e implementação de
        indicadores ESG (Ambiental / Social e Governança) que estão diretamente
        relacionados às ações empresariais, com a finalidade de mostrar o
        compromisso de sua empresa com esses temas.
      </>
    ),
  },
]

const CompliancePage: React.FC<PageProps> = ({ location }) => {
  const { pathname } = location
  const methodologyItems = [
    {
      count: 1,
      title: 'Planejamento',
      description: (
        <>
          A fase do planejamento é um processo comercial. Neste momento ainda
          não houve a contratação do serviço. O objetivo desta etapa é alinhar
          todas as expectativas do projeto e os objetivos junto ao cliente.
        </>
      ),
    },
    {
      count: 2,
      title: 'Diagnóstico',
      description: (
        <>
          Nessa etapa, coletamos informações através de entrevistas com os
          pontos focais de cada processo. Levantamos o contexto da organização,
          para entender fatores internos e externos, partes interessadas,
          políticas, procedimentos e riscos inerentes ao negócio.
        </>
      ),
    },
    {
      count: 3,
      title: 'Análise e melhoria',
      description: (
        <>
          Após concluído o diagnóstico, será apresentado um relatório que
          demonstra o nível de maturidade da organização em relação às boas
          práticas de compliance e governança corporativa. São fornecidos
          treinamentos para sustentação da nova gestão.
        </>
      ),
    },
    {
      count: 4,
      title: 'Implementação',
      description: (
        <>
          Acompanhamos a implementação das ações para adequação às boas práticas
          de compliance e governança corporativa. Fornecemos suporte para
          auditorias de conformidade, elaboração de políticas e procedimentos e
          apoio nas decisões tomadas nas reuniões de conselho.
        </>
      ),
    },
  ]

  return (
    <Layout location={location}>
      <SEO title="High Jump - Compliance" />
      <div className={styles.solutions}>
        <Menu mainFolder={pathname} />
        <main>
          <section className={clsx(styles.intro, styles.singleTitle)}>
            <img src={mainHeader} alt="High jump Consulting" />
            <div className={clsx(styles.mainTitle)}>
              <h1>
                Tome decisões assertivas, minimize riscos e incidência de
                fraudes{' '}
              </h1>
              <p>
                Conheça as nossas soluções para implementação de sistemas gestão
                de Compliance e Governança Corporativa para obtenção das
                certificações ISO – 27001, 37001 e 37301.
              </p>
            </div>
          </section>
          <div className={styles.container}>
            <div className={styles.intention}>
              <div className={styles.title}>
                <h1>Nosso Propósito</h1>
                <span></span>
                <p>
                  Implementar boas práticas de compliance para reduzir a
                  incidência de fraudes e não conformidades que implicam desvio
                  de recursos, riscos de sanções legais, perdas financeiras e
                  impacto na reputação da empresa.
                </p>
              </div>
              <div className={styles.mainFeatures}>
                <div className={styles.listItems}>
                  {featuresItems.map((item, key) => (
                    <div className={styles.item} key={key}>
                      <h4>{item.title}</h4>
                      <p>{item.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <MethodologySection
              items={methodologyItems}
              descriptionSection="Conheça os 04 principais passos de nossa metodologia para implementação da governança corporativa."
            />
          </div>

          <div className={styles.cases}>
            <div className={styles.title}>
              <h1>Quem conhece, confia!</h1>
            </div>
            <SliderCustomers customers={sgiList} />
          </div>

          <div className={styles.statistics}>
            <StatisticsSection />
          </div>

          <div className={styles.container}>
            <div className={styles.features}>
              <div className={styles.title}>
                <h1>Diferenciais</h1>
              </div>
              <div className={styles.items}>
                <div className={styles.div1}>
                  <h2>Equipe multidisciplinar</h2>
                  <p>
                    Especialistas por área, trazendo as melhores práticas de
                    mercado
                  </p>
                </div>
                <div className={styles.div2}>
                  <h2>Certificações</h2>
                  <p>Auditores líderes certificados IRCA</p>
                </div>
                <div className={styles.div3}>
                  <h2>Documentação completa</h2>
                  <p>Fornecemos modelos, manuais e procedimentos</p>
                </div>
                <div className={styles.div4}>
                  <h2>Cronograma personalizado</h2>
                  <p>Para atender o prazo solicitado pelo cliente</p>
                </div>
              </div>
            </div>
          </div>

          <Contact locationPath={pathname} />
          <FaqSection items={faqItems} />
          <Footer />
        </main>
      </div>
    </Layout>
  )
}

export default CompliancePage
